<template>
  <div class="reset-password min-h-screen container py-16 lg:p-32 flex items-center justify-center">
    <div class="login-container shadow-login rounded max-w-full flex flex-grow-0 flex-col items-center justify-center bg-white py-10 px-5 sm:p-10">
      <logo class="flex-shrink-0 mx-auto mb-1" />
      <h1 class="mb-12">acesso ao painel de gestão</h1>

      <div class="login-form-container flex flex-col items-center justify-start">
        <h4 class="self-start font-light text-lg text-black opacity-40 mb-4">Cadastre sua nova senha</h4>

        <nivea-form :submit="submit" :error="error" class="flex flex-col items-center justify-center">
          <input class="hidden" type="email" name="email" v-model="form.email" autocomplete="username" />

          <field name="password" label="Nova senha" v-slot="{ label }">
            <v-text-field vclass="pr-28" type="password" name="password" v-model="form.password" autocomplete="new-password" :label="label" hide-details="auto" />
          </field>

          <field name="confirm-new-password" label="Confirme a nova senha" rules="confirmed:password" vid="confirmation" data-vv-as="password" v-slot="{ label }">
            <v-text-field type="password" name="new-password" v-model="form.password_confirmation" autocomplete="new-password" :label="label" hide-details="auto" />
          </field>

          <Button class="w-36 mt-10" type="submit" :loading="loading">Cadastrar</Button>
        </nivea-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api.js';
import Logo from "@/components/Logo";
import NiveaForm from '@/components/NiveaForm';
import Field from "@/components/fields/Field";
import Button from "@/components/Button";

export default {
  name: "ResetPassword",
  components: {
    Logo,
    NiveaForm,
    Field,
    Button,
  },
  data() {
    return {
      loading: false,
      form: {
        email: this.$route.query.email || '',
        password: '',
        password_confirmation: ''
      }
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      await api.post('/admin/reset-password', {
        ...this.form,
        email:  this.$route.query.email,
        token: this.$route.query.token
      });

      this.loading = false;
      this.$router.push({ name: 'AdminLogin' });
      this.$noty.success('Sua nova senha foi cadastrada com sucesso. Tudo certo agora para você conseguir fazer seu login.');
    },
    error() {
      this.loading = false;
    }
  },
  mounted() {
    console.log('params', this.$route.query);
  }
};
</script>

<style lang="scss">
.reset-password {
  .login-container {
    height: auto;
  }
}
</style>
